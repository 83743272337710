
html,body{
  min-width: 1266px;
}
#header{
  position: fixed;
  background-color: #fff;
  height: 55px;
  line-height: 55px;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 4px 7px 0px rgba(22, 57, 104, 0.08);
  nav{
    position: relative;
    .logo{
      position: absolute;
      height: 55px;
      // background-color: aqua;
      left: 20px;
      top: 0;
      width: 200px;
      background-image: url('../img/wonderfab_logo_cn.svg');
      background-size: auto 38px ;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
    .linkHref{
      width: 694px;
      margin: 0 auto;
      a{
        padding: 0 28px;
        font-size: 14px;
        line-height: 55px;
        display: inline-block;
        cursor: pointer;
      }
      .active{
        color: #176BDF;
      }
    }
    .linkHref{
      a:hover {
        color:#176BDF;
      }
    }
    .international{
      position: absolute;
      right: 20px;
      top: 0;
      .internationalContainer{
        position: relative;
        padding-left: 26px;
        img{
          width: 18px;
          height: 18px;
          margin-right: 6px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        span{
          line-height: 55px;
          height: 55px;
          display: inline-block;
        }
        .active{
          color:#176BDF;
        }
        .lang{
          cursor: pointer;
        }
      }
    }
  }
}
#banner{
  padding-top: 55px;
  height: 533px;
  background-color: #2866EB;
  .owl-carousel .owl-item .ftco-cover-1{
    background-size: auto 100%;
    position: relative;
    overflow: hidden;
    img{
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
  }
}
@media screen and (max-width: 1700px){
  #banner{
    height: 483px;
    .owl-carousel-wrapper .owl-carousel .owl-item{
      height: 428px;
    }
  }
}
.owl-carousel-wrapper{
  height: 100%;
  position: relative;
  .owl-carousel{
    height: 100%;
    .owl-item{
      height: 478px;
      .ftco-cover-1{
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 60px 60px;
        *{
          moz-user-select: -moz-none; 
          -moz-user-select: none; 
          -o-user-select:none; 
          -khtml-user-select:none; 
          -webkit-user-select:none; 
          -ms-user-select:none; 
          user-select:none;
        }
        .banner-tit{
          font-size: 27px;
          margin-bottom: 40px;
          font-weight: 400;
          color:#1F2D58;
        }
        .banner-desc{
          font-size: 17px;
          line-height: 28px;
          color:#5B7299;
          width: 640px;
        }
      }
    }
  }
  .owl-carousel.owl-2{
    .owl-item {
      
    }
  }
  .owl-dots{
    width: 1266px;
    height: 8px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%,0);
    padding-left: 20px;

    .owl-dot{
      background-color: #fff;
      width: 8px;
      height: 8px;
      opacity: 0.6;
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
      border-radius: 0;
    }
    .owl-dot.active{
      width: 46px;
      opacity: 1;
    }
  }
  .owl-nav{
    opacity: 0;
    transition: all .3s;
  }
  
  .owl-prev{
    position: absolute;
    left: 160px;
    top: 50%;
    transform: translateY(-50%);
    .icon-arrow_back{
      width: 62px;
      height: 62px;
      display: block;
      background-image: url('../img/icon_banner_btn_left.svg');
    }
  }
  .owl-next{
    position: absolute;
    right: 160px;
    top: 50%;
    transform: translateY(-50%);
    .icon-arrow_forward{
      width: 62px;
      height: 62px;
      display: block;
      background-image: url('../img/icon_banner_btn_right.svg');
    }
  }
  @media screen and (max-width: 1700px) {
    .owl-prev{
      left: 30px;
    }
    .owl-next{
      right: 30px;
    }
  }
}
.owl-carousel-wrapper:hover{
  .owl-nav{
    opacity: 1;
  }
}
.tit{
  font-size: 28px;
  line-height: 50px;
  position: relative;
  color: #141414;
  text-align: center;
}
.tit.border-s{
  &::after{
    content: " ";
    width: 68px;
    height: 4px;
    // background-color: #366CC6;
    background: linear-gradient(90deg, #2866EB 0%, #40B2D8 100%);
    position: absolute;
    bottom:-4px;
    left:50%;
    transform: translateX(-50%);
  }
}
.content{
  margin-top: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
}
#companyProfile{
  height: 356px;
  padding-top: 74px;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    border-width: 0 30vw 38px 0;
    border-style: solid;
    border-color: transparent transparent #C9D9F5;
  }
  &::after{
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    border-width: 0 0  38px 30vw;
    border-style: solid;
    border-color: transparent transparent #C9D9F5;
  }
}

#service{
  height: 494px;
  .tit{
    padding-top: 62px;
    color:#fff;
  }
  background-color: #366CC6;
  background-image: url('../img/img_bg_fuwuyuzhichi.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .listContent{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .list-item{
      width: 288px;
      height: 296px;
      background-color: rgba(30, 100, 196, 0.6);
      padding-top: 122px;
      position: relative;
      padding: 30px 24px;
      .icon{
        position: absolute;
        top: 30px;
        left: 24px;
        width: 62px;
        height: 62px;
      }
      .tit-m{
        margin-top: 92px;
        color:#fff;
        font-size: 20px;
      }
      .cont{
        margin-top: 26px;
        color:#fff;
        line-height: 24px;
        font-size: 14px;
      }
    }
    .list-item:nth-child(1) {
      .icon{
        background-image: url('../img/icon_processtech.svg');
      }
    }
    .list-item:nth-child(2) {
      .icon{
        background-image: url('../img/icon_design.svg');
      }
    }
    .list-item:nth-child(3) {
      .icon{
        background-image: url('../img/icon_server.svg');
      }
    }
    .list-item:nth-child(4) {
      .icon{
        background-image: url('../img/icon_completeserver.svg');
      }
    }
  }
}

#tech{
  height: 780px;
  max-width: 100%;
  overflow: hidden;
  .tit{
    padding-top: 64px;
  }
  .tabContainer{
    width: 1266px;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    margin-top: 30px;
    border-bottom: 1px solid #E8EEF9;
    display: flex;
    justify-content: space-between;
    padding: 0 400px;
    .tab-tit{
      font-size: 20px;
      cursor: pointer;
    }
    .tab-tit:hover{
      color:#176BDF;
    }
    .tab-tit.active{
      border-bottom: 4px solid #176BDF;
      // border-image: linear-gradient(90deg, #2866EB 0%, #40B2D8 100%);
      // border-image-slice: 10;
      color:#176BDF;
    }
  }
  #techBanner{
    margin-top: 54px;
    height: 480px;
    width: 1266px;
    margin: 10px auto 0;
    .owl-carousel-wrapper{
      height: 500px;
      .owl-stage-outer{
        height: 100%;
      }
      .owl-item{
        margin-top: 14px;
        height: 460px;
        // padding: 0 22px;
        .ftco-cover-1{
          width: 1266px;
          // box-shadow: 0px 0px 18px 0px rgba(71, 124, 218, 0.2);
          background-size: auto 100%;
          overflow: hidden;
          position: relative;
          img{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: auto;
            z-index: -1;
          }
        }
      }
      .owl-dots{
        margin: 0 auto;
        width: auto;
        bottom: -14px;
        .owl-dot{
          display: inline-block;
          border-radius: 4px;
          height: 8px;
          background-color: #CADEFF;
        }
        .owl-dot.active{
          background-color: #366CC6;
          width: 8px;
        }
      }
      .owl-nav{
        opacity: 1;
        .owl-prev{
          left: -70px;
          .icon-arrow_back_2{
            width: 50px;
            height: 50px;
            display: block;
            background-image: url('../img/icon_btn_tech_1.svg');
            background-repeat: no-repeat;
          }
        }
        .owl-next{
          right: -70px;
          .icon-arrow_forward_2{
            width: 50px;
            height: 50px;
            display: block;
            background-image: url('../img/icon_btn_tech_2.svg');
            background-repeat: no-repeat;
          }
        }
      }
      .owl-carousel{
        height: 0;
        overflow: hidden;
      }
      .owl-carousel.active{
        height: inherit;
        overflow: visible;
      }
    }
  }
}
#apply{
  background-color: #F2F6FF;
  .tit{
    padding-top: 64px;
    margin-bottom: 40px;
  }
  .typeContainer{
    display: flex;
    justify-content: space-between;
    .typeList{
      width: 100%;
      height: 320px;
      padding: 50px 60px;
      letter-spacing: 2px;
      font-size: 24px;
      color:#fff;
      background-position: center center;
      background-size: auto 100%;
    }
    // @media screen and (max-width: 1866px) {
    //   .typeList{
    //     background-size: auto 100% ;
    //   }
    // }
    @media screen and (min-width: 1867px) {
      .typeList{
        background-size: 100% 100%  ;
      }
    }
  }
  .typeContainer:nth-child(2){
    .typeList:nth-child(1) {
      background-image: url('');
    }
    .typeList:nth-child(2) {
      background-image: url('');
    }
    .typeList:nth-child(3) {
      background-image: url('');
    }
  }
  .typeContainer:nth-child(3) {
    .typeList:nth-child(1) {
      background-image: url('');
    }
    .typeList:nth-child(2) {
      background-image: url('');
    }
    .typeList:nth-child(3) {
      background-image: url('');
    }
  }
}

#partner{
  height: 304px;
  position: relative;
  .partner-typeArea{
    width: 970px;
    margin: 0 auto;
  }
  .tit{
    padding-top: 58px;
  }
  .owl-stage-outer{
    overflow: hidden;
    position: relative;
    margin-top: 52px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 110px;
      background-image: linear-gradient(to right,#fff , rgba(255,255,255,0));
      z-index: 4;
    }
    &::after{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100px;
      background-image: linear-gradient(to left,#fff , rgba(255,255,255,0));
      z-index: 4;
    }
    .owl-item{
      display: inline-block;
      vertical-align: middle;
      .single-brand{
        a{
          width: 300px;
          display: inline-block;
        }
        img{
          // width: 100%;
          vertical-align: top;
          margin: 0 auto;
        }
      }
    }
  }
  .owl-nav.disabled{
    display: none;
  }
  .consult{
    margin-top: 180px;
    width: 100%;
    height: 170px;
    background-color: #2A6AD5;
    background-image: url('../img/img_bg_contact.png');
    background-size: auto 100%;
    background-position: center;
    .consult-container{
      display: flex;
      width: 1266px;
      margin: 0 auto;
      justify-content: space-between;
      padding: 50px 56px 0 70px;
      color:#fff;
      .left{
        .cons-tit{
          font-size: 25px;
        }
        .cons-cont{
          font-size: 14px;
          margin-top: 18px;
          letter-spacing: 1px;
        }
      }
      .right{
        .number{
          .icon{
            width: 28px;
            height: 28px;
            line-height: 70px;
            margin-right: 10px;
          }
          a{
            font-size: 25px;
            line-height: 70px;
            height: 70px;
            display: inline-block;
            color: #fff;
          }
        }
      }
    }
  }
}

#client-carousel{
  .owl-stage{
    .owl-item{
      a{
        display: block !important;
        margin: 0 auto;
        img{
          height: 54px;
          width: auto;
          display: block;
        }
        .hkst{
          // float: right;
          height: 44px;
        }
        .cityu{
          height: 64px;
        }
      }
    }
    .active:last-child {
      padding-left: 80px;
      background-color: #000;
    }
  }
}
footer{
  background: linear-gradient(90deg, #155CB1 0%, #236FCA 100%);
  height: 200px;
  padding-top: 58px;
  .fabContainer{
    display: flex;
    width: 800px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .fab-name{
    width: 290px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
  .fab-en-name{
    width: 290px;
    color: #fff;
    font-size: 15px;
    margin-top: 15px;
  }
  .contactBox{
    width: 492px;
  }
  .contact{
    display: block;
    font-size: 15px;
    color:#fff;
    margin-bottom: 15px;
    white-space: nowrap;
    a{
      color:#fff;
    }
  }
  .link{
    display: block;
    margin: 0 auto;
    width: 804px;
    color:#fff;
  }
}

#enBody{
  #header{
    nav .logo{
      background-image: url('../img/wonderfab_logo_en.svg');
    }
  }
  #companyProfile{
    padding-bottom: 85px;
    height: auto;
  }
  #service{
    height: auto;
    padding-bottom: 64px;
    .list-item{
      height: auto;
    }
  }
  .owl-carousel-wrapper .owl-carousel .owl-item .ftco-cover-1 .banner-desc{
    width: 720px;
    line-height: 26px;
  }
  #partner .consult .consult-container{
    .subtit{
      margin-top: 10px;
    }
  }
  #tech .tabContainer{
    padding: 0 280px;
  }
  .fabContainer{
    // width: 1000px;
  }
}